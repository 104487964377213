import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path
      d="M9 13.5a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm7-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0-7a1 1 0 1 0 0-2 1 1 0 0 0 0 2Zm0 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z"
      stroke="#B3B9C9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </>,
  'DragHandle',
  {
    viewBox: '0 0 24 24',
    size: 24,
  },
);
