import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M8.00001 3.33333C5.62073 3.33333 3.65645 5.11449 3.36948 7.41583C3.32392 7.78119 2.9908 8.04044 2.62544 7.99488C2.26008 7.94932 2.00083 7.6162 2.04639 7.25084C2.41555 4.29043 4.93979 2 8.00001 2C9.88555 2 11.5671 2.86964 12.6667 4.22865V3.33333C12.6667 2.96514 12.9651 2.66667 13.3333 2.66667C13.7015 2.66667 14 2.96514 14 3.33333V6C14 6.36819 13.7015 6.66667 13.3333 6.66667H12.9612C12.951 6.6669 12.9408 6.6669 12.9306 6.66667H10.6667C10.2985 6.66667 10 6.36819 10 6C10 5.63181 10.2985 5.33333 10.6667 5.33333H11.8304C10.9867 4.12378 9.58513 3.33333 8.00001 3.33333Z" fill="currentColor" />
    <path d="M13.3746 8.00512C13.7399 8.05068 13.9992 8.3838 13.9536 8.74916C13.5845 11.7096 11.0602 14 8.00001 14C6.11446 14 4.43292 13.1304 3.33333 11.7713V12.6667C3.33333 13.0349 3.03486 13.3333 2.66667 13.3333C2.29848 13.3333 2 13.0349 2 12.6667L2 10C2 9.63181 2.29848 9.33333 2.66667 9.33333H3.03887C3.04906 9.3331 3.05924 9.3331 3.06939 9.33333H5.33333C5.70152 9.33333 6 9.63181 6 10C6 10.3682 5.70152 10.6667 5.33333 10.6667H4.16962C5.01333 11.8762 6.41488 12.6667 8.00001 12.6667C10.3793 12.6667 12.3436 10.8855 12.6305 8.58417C12.6761 8.21881 13.0092 7.95956 13.3746 8.00512Z" fill="currentColor" />
  </>,
  'Reload',
  {
    color: 'chambrayBlue',
    viewBox: '0 0 16 16',
  },
);
