import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M10 3H3V10H10V3Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M21 3H14V10H21V3Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M21 14H14V21H21V14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M10 14H3V21H10V14Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </>,
  'Grid',
  {
    color: 'riverBlue',
    viewBox: '0 0 24 24',
    fill: 'none',
    size: 24,
  },
);
