import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M1.6666 1.5 13.3333 9 1.6666 16.5v-15Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </>,
  'PlayOutline',
  {
    size: 18,
    viewBox: '0 0 15 18',
    fill: 'none',
  },
);
