import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M13.7806 7.71361C14.0596 7.42885 14.5118 7.42885 14.7908 7.71361C15.0697 7.99837 15.0697 8.46005 14.7908 8.74481L10.5051 13.1198C10.2347 13.3959 9.79925 13.4055 9.51734 13.1417L5.23163 9.1313C4.94083 8.85918 4.92119 8.39793 5.18775 8.10108C5.45432 7.80422 5.90615 7.78416 6.19695 8.05628L9.97852 11.5949L13.7806 7.71361Z" fill="currentColor" />
  </>,
  'ChevronDown',
  {
    viewBox: '0 0 20 20',
  },
);
