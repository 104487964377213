import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M10.5385 19.3275a.5252.5252 0 0 1-.3901-.1671l-5.5728-5.5728a.5518.5518 0 0 1 .7802-.7804l5.1381 5.1271L24.0358 1.149a.5582.5582 0 0 1 .3774-.2422.5572.5572 0 0 1 .2287.012.556.556 0 0 1 .4139.499.5577.5577 0 0 1-.1506.4224l-13.932 17.2756a.5786.5786 0 0 1-.4012.2117h-.0335Z" fill="#fff" />
    <path d="M21.1232 24.9002H1.0612a.5573.5573 0 0 1-.5573-.5572V4.281a.5572.5572 0 0 1 .5573-.5574h14.4892a.5574.5574 0 0 1 .3941.9513.5574.5574 0 0 1-.3941.1633H1.6184v18.9475H20.566V12.0828a.5572.5572 0 0 1 1.1146 0V24.343a.5572.5572 0 0 1-.5573.5572Z" fill="#fff" />
  </>,
  'Check',
  {
    viewBox: '0 0 26 25',
    fill: 'none',
    size: 26,
  },
);
