import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path clipRule="evenodd" d="M8.7071.2929c-.3905-.3905-1.0237-.3905-1.4142 0l-7 7c-.3905.3905-.3905 1.0237 0 1.4142l7 7c.3905.3905 1.0237.3905 1.4142 0 .3905-.3905.3905-1.0237 0-1.4142L3.4142 9H15c.5523 0 1-.4477 1-1s-.4477-1-1-1H3.4142l5.293-5.2929c.3904-.3905.3904-1.0237 0-1.4142Z" fill="#325C92" fillRule="evenodd" />
  </>,
  'ArrowLeft',
  {
    viewBox: '0 0 16 16',
    size: 16,
    fill: 'none',
  },
);
