import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path clipRule="evenodd" d="M1.4631 1.2268a2 2 0 0 1 1.9943-.0098l44.8021 25.4788a1.9998 1.9998 0 0 1 0 3.477L3.4575 55.6516a2 2 0 0 1-2.9888-1.7385V2.9556a2 2 0 0 1 .9944-1.7288Z" fill="#fff" fillRule="evenodd" />
  </>,
  'Play',
  {
    size: 24,
    viewBox: '0 0 50 56',
    fill: 'none',
  },
);
