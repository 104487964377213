import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path clipRule="evenodd" d="M6.5 2.182c-.199 0-.39.08-.53.222a.761.761 0 0 0-.22.535v.758h4.5v-.758c0-.2-.079-.393-.22-.535a.746.746 0 0 0-.53-.222h-3Zm5.25 1.515v-.758c0-.602-.237-1.18-.659-1.607A2.239 2.239 0 0 0 9.5.667h-3c-.597 0-1.169.24-1.591.665A2.284 2.284 0 0 0 4.25 2.94v.758h-3c-.414 0-.75.34-.75.758s.336.757.75.757H2v9.849c0 .603.237 1.18.659 1.607.422.426.994.665 1.591.665h7.5c.597 0 1.169-.239 1.591-.665A2.284 2.284 0 0 0 14 15.06V5.212h.75c.414 0 .75-.339.75-.757a.754.754 0 0 0-.75-.758h-3ZM3.5 5.212v9.849c0 .2.079.393.22.535.14.142.331.222.53.222h7.5c.199 0 .39-.08.53-.222a.761.761 0 0 0 .22-.535V5.212h-9Z" fill="#325C92" fillRule="evenodd" />
  </>,
  'Delete',
  {
    viewBox: '0 0 16 20',
    size: 20,
  },
);
