import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="m23.8334 13.5488-4.6667 3.3334 4.6667 3.3333v-6.6667ZM17.8334 12.2158h-7.3333c-.7364 0-1.3333.597-1.3333 1.3334v6.6666c0 .7364.5969 1.3334 1.3333 1.3334h7.3333c.7364 0 1.3333-.597 1.3333-1.3334v-6.6666c0-.7364-.5969-1.3334-1.3333-1.3334Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M.5 15.8823c0-8.8365 7.1634-16 16-16s16 7.1635 16 16v1c0-8.2843-7.1634-15-16-15s-16 6.7158-16 15v-1Zm32 17H.5h32Zm-32 0v-32 32Zm32-32v32-32Z" fill="#fff" mask="url(#path-1-inside-1_13061_444102)" />
  </>,
  'VideoCamera',
  {
    size: 33,
    viewBox: '0 0 33 33',
    fill: 'none',
  },
);
