import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M15.3346 12.6667c0 .3536-.1404.6927-.3905.9428-.25.25-.5892.3905-.9428.3905h-12c-.35362 0-.69276-.1405-.94281-.3905-.250045-.2501-.390521-.5892-.390521-.9428V5.33333c0-.35362.140476-.69276.390521-.94281C1.30854 4.14048 1.64768 4 2.0013 4h2.66667L6.0013 2h4l1.3333 2h2.6667c.3536 0 .6928.14048.9428.39052.2501.25005.3905.58919.3905.94281v7.33337Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M7.9987 11.3333c1.47276 0 2.6667-1.1939 2.6667-2.66663C10.6654 7.19391 9.47146 6 7.9987 6S5.33203 7.19391 5.33203 8.66667c0 1.47273 1.19391 2.66663 2.66667 2.66663Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" />
  </>,
  'Camera',
  {
    viewBox: '0 0 16 16',
    fill: 'none',
  },
);
