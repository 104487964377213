import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="m15 19-7-5-7 5V3a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16Z" stroke="#325C92" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </>,
  'Bookmark',
  {
    viewBox: '0 0 16 20',
    size: 20,
    fill: 'none',
  },
);
