import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path clipRule="evenodd" d="M7.5287 2.8618a.6667.6667 0 0 1 .9428 0l4.6667 4.6666a.6668.6668 0 0 1 0 .9428L8.4715 13.138a.6667.6667 0 1 1-.9428-.9428l3.5286-3.5286H3.3334a.6667.6667 0 1 1 0-1.3333h7.7239L7.5287 3.8046a.6667.6667 0 0 1 0-.9428Z" fill="currentColor" fillRule="evenodd" />
  </>,
  'ArrowRight',
  {
    viewBox: '0 0 16 16',
    size: 16,
    fill: 'none',
  },
);
