import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path
      clipRule="evenodd"
      d="M4.411 4.411a.833.833 0 0 1 1.179 0L10 8.821l4.411-4.41A.833.833 0 1 1 15.59 5.59L11.179 10l4.41 4.411a.833.833 0 0 1-1.178 1.179l-4.41-4.411-4.411 4.41a.833.833 0 0 1-1.179-1.178l4.41-4.41-4.41-4.411a.833.833 0 0 1 0-1.179Z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </>,
  'Close',
  {
    viewBox: '0 0 20 20',
  },
);
