import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path clipRule="evenodd" d="M21 41C9.9543 41 1 32.0457 1 21S9.9543 1 21 1s20 8.9543 20 20-8.9543 20-20 20Z" fill="#fff" fillRule="evenodd" stroke="#FF9D79" />
    <path d="M29.925 21.7617a.5001.5001 0 0 0 0-.7071l-3.182-3.182a.5.5 0 0 0-.7071 0 .5.5 0 0 0 0 .7071l2.8284 2.8284-2.8284 2.8285a.5.5 0 1 0 .7071.7071l3.182-3.182Zm-19.1291.1464h18.7755v-1H10.7959v1Z" fill="#FF9D79" />
  </>,
  'ArrowRightRound',
  {
    viewBox: '0 0 44 44',
    size: 44,
    fill: 'none',
  },
);
