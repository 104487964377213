import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M8 6H21M8 12H21M8 18H21M3 6H3.01M3 12H3.01M3 18H3.01" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </>,
  'List',
  {
    color: 'riverBlue',
    viewBox: '0 0 24 24',
    fill: 'none',
    size: 24,
  },
);
