import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M10 5C11.1046 5 12 4.10457 12 3C12 1.89543 11.1046 1 10 1C8.89543 1 8 1.89543 8 3C8 4.10457 8.89543 5 10 5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M1 3L7 3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M15 3L12 3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M6 9C4.89543 9 4 9.89543 4 11C4 12.1046 4.89543 13 6 13C7.10457 13 8 12.1046 8 11C8 9.89543 7.10457 9 6 9Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M15 11H8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M1 11H3" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
  </>,
  'Filters',
  {
    viewBox: '0 0 16 14',
    fill: 'none',
    color: 'chambrayBlue',
  },
);
