import createSvgIcon from '../createSvgIcon';

export default createSvgIcon(
  <>
    <path d="M8 16L16 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <path d="M16 16L8 8" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" />
  </>,
  'Mistake',
  {
    viewBox: '0 0 24 24',
    size: 24,
    fill: 'none',
  },
);
